<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <SOTrackerTable />
    </b-card>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import SOTrackerTable from '@/components/salesordertracker/TrackerTable.vue'

export default {
  name: 'WarehouseSalesOrderTracker',

  components: {
    SOTrackerTable
  },

  middleware: ['auth', 'warehouse'],

  metaInfo () {
    return {
      title: 'Sales Order Tracker'
    }
  },

  mounted () {
    core.index()
  }
}
</script>
